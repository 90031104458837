import { Breadcrumb } from 'antd'

import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'

import type { BreadcrumbInfo } from 'models/motion/motionBuilder.model'

interface GetBreadcrumbType {
  breadcrumb: BreadcrumbInfo
  index: number
  handleSelectBreadcrumb: (index: number) => void
}

export function getBreadcrumb(props: GetBreadcrumbType): JSX.Element {
  const { breadcrumb, index, handleSelectBreadcrumb } = props
  // TODO: Remove the replace of snowflakedb once isn't needed anymore (MAGPROD-3416) - or we entirely migrate to Airbyte
  const breadcrumbName = breadcrumb.magnifyDisplayName ?? breadcrumb.name?.replace('snowflakedb', 'snowflake')

  return (
    <Breadcrumb.Item key={`${breadcrumb.name}-${index}`} className='breadcrumb__item'>
      <button
        type='button'
        onClick={() => {
          handleSelectBreadcrumb(index)
        }}>
        <div className='breadcrumb__image'>{getMenuItemIcon(breadcrumb, true)}</div>
        <div className='breadcrumb__name'>
          <span title={breadcrumbName}>{breadcrumbName}</span>
        </div>
      </button>
    </Breadcrumb.Item>
  )
}
