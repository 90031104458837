import CompositeCell from 'components/common/CompositeCell'
import PercentageCell from 'components/common/PercentageCell'
import RevenueCell from 'components/common/RevenueCell/index'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { calculateClassName, getPredictionValue, NumberFormat } from 'services/Utils'

import type { DriverStats, Level, RevenueStats } from 'models/dashboard.model'
import type { FileAttachment } from 'models/file-attachments.model'
import { DataFormatEnum, TableColumnTypeEnum } from 'models/insights'
import type { AccountForecastTableRow, Impact, RangeValue, SegmentRow, TableColumn } from 'models/insights'
import type { AccountListRow, Composite, EmailStatistics } from 'models/reporting.model'

interface DynamicCellProps {
  column: TableColumn
  record: AccountForecastTableRow | AccountListRow | EmailStatistics | SegmentRow | FileAttachment
  impact?: Impact
  openModal?: () => void
  className?: string
}

const NOT_APPLICABLE = 'N/A'

const DynamicCell = ({ column, record, impact, openModal, className = '' }: DynamicCellProps) => {
  let value = 0
  const format = column.format ?? DataFormatEnum.Comma
  const decimal = column.decimal ?? 1
  const columnData: Record<string, string | number> = record?.[column.key] as Record<string, string | number>
  const rowSegment: string = (columnData?.segment as string) ?? ''

  if (columnData?.['value']) {
    value = columnData?.['value'] as number
  } else if (columnData && !columnData.hasOwnProperty('value')) {
    value = record[column.key] as number
  }

  switch (column.type) {
    case TableColumnTypeEnum.Number: {
      return (
        <div className={`cell--number ${calculateClassName(rowSegment, impact, column.key)} ${className}`}>
          {value || value === 0 ? formatNumber({ number: value, format, decimal }) : 0}
        </div>
      )
    }

    case TableColumnTypeEnum.Percentage:
    case TableColumnTypeEnum.Percent: {
      if (typeof value === 'string') {
        return (
          <div className={`cell--number ${calculateClassName(rowSegment, impact, column.key)} ${className}`}>
            {value ?? NOT_APPLICABLE}
          </div>
        )
      }

      return (
        <div className={`cell--number ${calculateClassName(rowSegment, impact, column.key)} ${className} `}>
          {value || value === 0 ? `${NumberFormat.beautifyFloat(value * 100, decimal)}%` : NOT_APPLICABLE}
        </div>
      )
    }

    case TableColumnTypeEnum.String:
    case TableColumnTypeEnum.Str: {
      // provisory change as part of MAGPROD-840 hotfix
      if (column.key === 'name' && openModal) {
        return (
          <div className={`cell--string clickable ${className}`} onClick={openModal}>
            {value ?? NOT_APPLICABLE}
          </div>
        )
      } else {
        return <div className={`cell--string ${className}`}>{value === 0 ? '—' : value}</div>
      }
    }

    case TableColumnTypeEnum.Range: {
      const range = record[column.key] as RangeValue
      const isRangeValid = range[0] !== null && range[1] !== null
      return (
        <div className={`cell--range ${className}`}>
          {isRangeValid ? (
            <>
              <span className='min'>{NumberFormat.beautifyFloat(range[0])}</span>
              <span className='to'>{' to '}</span>
              <span className='max'>{NumberFormat.beautifyFloat(range[1])}</span>
            </>
          ) : (
            NOT_APPLICABLE
          )}
        </div>
      )
    }

    case TableColumnTypeEnum.Prediction: {
      let displayValue = (
        <span className={`${calculateClassName(value as Level, impact, column.key)} ${className}`}>
          {getPredictionValue(value as Level)}
        </span>
      )
      if (record.propensity) {
        displayValue = (
          <>
            {record.propensity}{' '}
            <span className={`${calculateClassName(value as Level, impact, column.key)} ${className}`}>
              {getPredictionValue(value as Level)}
            </span>
          </>
        )
      }
      return <div className={`cell--prediction ${className}`}>{displayValue}</div>
    }

    case TableColumnTypeEnum.ImpactPercentage: {
      const driverStats: DriverStats = record[column.key] as DriverStats
      return (
        <PercentageCell
          level={driverStats.level}
          value={driverStats.prediction}
          impact={column?.impact as Impact}
          columnKey={column.key}
          className={className}
        />
      )
    }

    case TableColumnTypeEnum.RevenueForecast: {
      const revenueStats: RevenueStats = record[column.key] as RevenueStats
      return <RevenueCell amount={revenueStats.amount} change={revenueStats.change} className={className} />
    }

    case TableColumnTypeEnum.Composite: {
      if (!record[column.key]) {
        return <span>{NOT_APPLICABLE}</span>
      }
      const { new: newValue, old, unit, impact }: Composite = record[column.key] as Composite
      return <CompositeCell new={newValue} old={old} unit={unit} impact={impact} className={className} />
    }

    default:
      return <div className={`cell--default ${className}`}>{value || NOT_APPLICABLE}</div>
  }
}
DynamicCell.displayName = 'DynamicCell'

export default DynamicCell
